
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-feature-settings: 'clig' off, 'liga' off;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/design_assets/Proxima_Nova_Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
